// user.module.js

import { clinicService } from '../../helpers/backend/Doctor/clinic.service';
import { patientService } from '../../helpers/backend/Doctor/patient.service';
import { doctorAppointmentService } from '../../helpers/backend/Doctor/doctorAppointment.service';
import { appointmentService } from '../../helpers/backend/appointment.service';
import { prescriptionService } from '../../helpers/backend/Doctor/prescription.service';
import { userService } from '../../helpers/backend/Doctor/user.service';

const listing = [];
const state = {
    listing: listing
};

const actions = {
    //Clinic APIs
    async getClinicList({ dispatch, commit }, { search = "", existingPage = 1, perPage = 10000, is_active = '' }) {
        return clinicService.getClinic(search, existingPage, perPage, is_active)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async storeClinic({ dispatch, commit }, clinic) {
        return clinicService.storeClinic(clinic)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async deleteClinic({ dispatch, commit }, { id }) {
        return clinicService.deleteClinic(id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async getClinicByID({ dispatch, commit }, { id }) {
        return clinicService.getClinicByID(id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async updateClinic({ dispatch, commit }, { id, clinic }) {
        return clinicService.updateClinic(id, clinic)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async updateStatusClinic({ dispatch, commit }, { is_active, id }) {
        return clinicService.updateStatusClinic(is_active, id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    //Patient API
    async getPatientList({ dispatch, commit }, { search = "", existingPage = 1, perPage = 10000, clinicID = 0, doctorID = 0

    }) {
        return patientService.getPatient(search, existingPage, perPage, clinicID, doctorID)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async storePatient({ dispatch, commit }, clinic) {
        return patientService.storePatient(clinic)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async deletePatient({ dispatch, commit }, { id }) {
        return patientService.deletePatient(id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async getPatientByID({ dispatch, commit }, { id }) {
        return patientService.getPatientByID(id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async updatePatient({ dispatch, commit }, { id, clinic }) {
        return patientService.updatePatient(id, clinic)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async updateStatusPatient({ dispatch, commit }, { is_active, id }) {
        return patientService.updateStatusPatient(is_active, id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    //Patient API
    async getDoctorAppointmentList({ dispatch, commit }, { search = "", existingPage = 1, perPage = 10000, doctorID = 0, clinicID = 0 }) {
        return doctorAppointmentService.getDoctorAppointment(search, existingPage, perPage, doctorID, clinicID)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async storeDoctorAppointment({ dispatch, commit }, clinic) {
        return doctorAppointmentService.storeDoctorAppointment(clinic)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async updateDoctorAppointment({ dispatch, commit }, { id, patient }) {
        return doctorAppointmentService.updateDoctorAppointment(id, patient)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async deleteDoctorAppointment({ dispatch, commit }, { id }) {
        return doctorAppointmentService.deleteDoctorAppointment(id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async getDoctorAppointmentByID({ dispatch, commit }, { id }) {
        return doctorAppointmentService.getDoctorAppointmentByID(id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async checkAppointmentDate({ dispatch, commit }, { schedule_date, id }) {
        return appointmentService.checkAppointmentDate(schedule_date, id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async storeClinicTiming({ dispatch, commit }, data) {
        return clinicService.storeClinicTiming(data)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async storePrescription({ dispatch, commit }, prescription) {
        console.log("storePrescription fetchdata", prescription)
        return prescriptionService.storePrescription(prescription)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async getDashboard({ dispatch, commit }) {
        return userService.getDashboard()
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async clinicAppointment({ dispatch, commit }, data) {
        return clinicService.clinicAppointment(data)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },

    // async updateStatusPatient({ dispatch, commit }, { is_active, id }) {
    //     return patientService.updateStatusPatient(is_active, id)
    //         .then(
    //             result => {
    //                 return result;
    //             },
    //             error => {
    //                 return error;
    //             }
    //         );
    // },

};

const mutations = {

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}