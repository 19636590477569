import { authHeader } from '../auth-header';
import { reactive } from 'vue'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export const userDetails = reactive({
    user: {}
})
export const userService = {
    login,
    logout,
    register,
    changePassword,
    getDoctor,
    updateDoctorDetails,
    getDashboard
};

function register(user) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(import.meta.env.VITE_API_URL + `doctor/register`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.access_token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('doctorUser', JSON.stringify(user.result));
                //Token Store
                localStorage.setItem('token', JSON.stringify(user.access_token));
            }
            return user;
        });
}
function login(email, password) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    return fetch(import.meta.env.VITE_API_URL + `doctor/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.access_token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('doctorUser', JSON.stringify(user.result));
                //Token Store
                localStorage.setItem('token', JSON.stringify(user.access_token));
            }
            return user;
        });
}

function logout() {
    // remove user from local storage to log user out

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };
    return fetch(import.meta.env.VITE_API_URL + `doctor/logout`, requestOptions).then(handleResponse)
        .then(result => {
            localStorage.removeItem('doctorUser');
            localStorage.removeItem('token');
            return result;
        });
}


function changePassword(oldpassword, newpassword) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ oldpassword, newpassword })

    };
    return fetch(import.meta.env.VITE_API_URL + `changePassword`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function getDoctor() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(import.meta.env.VITE_API_URL + `doctorProfile`, requestOptions)
        .then(handleResponse)
        .then(result => {
            console.log("service", result);
            return result;
        });

}
function updateDoctorDetails(id, doctor) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(doctor)

    };
    return fetch(import.meta.env.VITE_API_URL + `doctorProfile/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {

            if (response.status == 400) {
                toast.error(data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            else if (response.status === 422) {
                toast.error(data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            else if (response.status === 401) {
                toast.error(data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                // auto logout if 401 response returned from api
                localStorage.removeItem('doctorUser');
                localStorage.removeItem('token');
                // logout();
                location.reload(true);
            }
            else if (response.status === 500) {
                toast.error("Internal Server Error", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else {
                toast.error(data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
function getDashboard() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(import.meta.env.VITE_API_URL + `dashboard`, requestOptions)
        .then(handleResponse)
        .then(result => {
            console.log("service", result);
            return result;
        });
}