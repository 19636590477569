import { authHeader, handleResponse } from './auth-header';

export const appointmentService = {
    getAppointment,
    storeAppointment,
    deleteAppointment,
    getAppointmentByID,
    updateAppointment,
    updateStatusAppointment,
    checkAppointmentDate
};

function getAppointment(search, existingPage, perPage) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(import.meta.env.VITE_API_URL + `appointment?search=` + search + `&page=` + existingPage + `&perPage=` + perPage, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function storeAppointment(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)

    };

    return fetch(import.meta.env.VITE_API_URL + `doctor_appointment`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function deleteAppointment(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify({ id })

    };

    return fetch(import.meta.env.VITE_API_URL + `doctor_appointment/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function updateAppointment(data, id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)

    };
    return fetch(import.meta.env.VITE_API_URL + `doctor_appointment/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function updateStatusAppointment(is_active, id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ is_active })

    };
    return fetch(import.meta.env.VITE_API_URL + `appointment/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function getAppointmentByID(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(import.meta.env.VITE_API_URL + `doctor_appointment/` + id + `/edit`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function checkAppointmentDate(schedule_date,id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ schedule_date,id })
    };
    return fetch(import.meta.env.VITE_API_URL + `doctor_appointment/checkAppointmentDate`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
