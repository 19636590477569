import { authHeader, handleResponse } from '../auth-header';

export const prescriptionService = {

    storePrescription,

};

function storePrescription(prescription) {
    console.log("storePrescription", JSON.stringify(prescription))
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(prescription)

    };

    return fetch(import.meta.env.VITE_API_URL + `prescription`, requestOptions)
        .then(handleResponse)
        .then(result => {
            alert(result)
            return result;
        });
}