import { authHeader, handleResponse } from '../auth-header';

export const clinicService = {
    getClinic,
    storeClinic,
    deleteClinic,
    getClinicByID,
    updateClinic,
    updateStatusClinic,
    storeClinicTiming,
    clinicAppointment
};

function getClinic(search, existingPage, perPage, is_active) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()

    };

    return fetch(import.meta.env.VITE_API_URL + `clinic?search=` + search + `&page=` + existingPage + `&perPage=` + perPage + `&is_active=` + is_active, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function storeClinic(clinic) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(clinic)

    };

    return fetch(import.meta.env.VITE_API_URL + `clinic`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function deleteClinic(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify({ id })

    };

    return fetch(import.meta.env.VITE_API_URL + `clinic/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function getClinicByID(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(import.meta.env.VITE_API_URL + `clinic/` + id + `/edit`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function updateClinic(id, clinic) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(clinic)

    };
    return fetch(import.meta.env.VITE_API_URL + `clinic/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function updateStatusClinic(is_active, id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ is_active })

    };
    return fetch(import.meta.env.VITE_API_URL + `clinic/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function storeClinicTiming(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)

    };

    return fetch(import.meta.env.VITE_API_URL + `clinicTiming`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function clinicAppointment(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)

    };

    return fetch(import.meta.env.VITE_API_URL + `prescriptionDetails`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}